@import url("https://fonts.googleapis.com/css?family=Great+Vibes|Muli:300,400,500,600,700&display=swap");

h1,
h2,
h3 {
  /* font-family: 'Great Vibes', cursive; */
  font-family: "Dancing Script", cursive;
}
body {
  font-family: "Muli", sans-serif;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
a:hover {
  text-decoration: none !important;
}
img {
  width: 100%;
}
.section-padding {
  padding: 180px 0 130px;
}

.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .section-padding {
    padding: 130px 0 100px;
  }
}

.fixed-navbar {
  position: relative;
  z-index: 5555;
}

.fixed-navbar.active .header {
  position: fixed;
  z-index: 555;
  top: 0;
  opacity: 1;
  left: 0;
  width: 100%;
  background: #85aaba;
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
