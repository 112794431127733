/*===============================
welcome-area
=================================*/

.welcome-area {
  /* background: url(../../images/slider/1.jpg); */
  background: url(../../images/GeriIvan/predlojenie-2-img.jpg);

  position: relative;
  height: 500px;
  background-attachment: fixed;
}
.welcome-area.s2 {
  /* background: url(../../images/imagesBubaNIkol/image-0-slide.jpeg); */
  background: url(../../images/GeriIvan/predlojenie-2-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media (max-width: 767px) {
  .welcome-area.s2 {
    display: flex;
    background-position: center;
    /* background-size: none; */
  }
}

.welcome-area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.9;
}

.welcome-content {
  text-align: center;
  padding: 100px 0;
}

.welcome-content h2 {
  font-size: 55px;
  color: #fff;
}

.welcome-content p {
  font-size: 18px;
  padding: 20px 50px 30px;
  color: #fff;
  max-width: 700px;
  margin: auto;
}

.btn a {
  display: inline-block;
  background: #fff;
  color: #8ba1aa;
  padding: 10px 38px;
  font-size: 18px;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 40px;
}

.btn a:hover {
  background: #b2c9d3;
  color: #fff;
}

button.btn {
  display: inline-block;
  background: #fff;
  color: #8ba1aa;
  padding: 10px 38px;
  font-size: 18px;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 40px;
  border: 0;
  box-shadow: none;
}

button.btn:hover {
  background: #b2c9d3;
  color: #fff;
}

@media (max-width: 767px) {
  .welcome-content {
    padding: 60px 0 30px;
  }

  .welcome-content p {
    font-size: 14px;
    padding: 20px 10px 30px;
  }
  .welcome-content h2 {
    font-size: 35px;
    color: #fff;
  }
  .welcome-content {
    padding: 85px 0 0;
  }
}

.modal-dialog {
  max-width: 1120px !important;
  margin: 200px auto !important;
}

.location-map iframe {
  width: 100%;
  height: 400px;
  border: 0;
}
