.Gallery-section{
    background: rgba(178,201,211,.1);
}
.gallery-img {
    cursor: pointer;
    margin-bottom: 30px;
    overflow: hidden;
}
.thumbnail{
    transform: scale(1);
    overflow: hidden;
    transition: all .3s;
}
.gallery-img:hover .thumbnail{
    transform: scale(1.1);
}

.image-box img{
    object-fit: cover;
}