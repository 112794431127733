.groomsmen-bridesmaid-area {
  background: rgba(178, 201, 211, 0.1);
  padding-bottom: 100px !important;
  
}

.groomsmen-bridesmaid-item {
  padding-top: 50px;
  
}

.groomsmen-bridesmaid-wrap {
  margin-bottom: 30px;
  overflow: hidden;
  z-index: -1;
  
}

.groomsmen-bridesmaid-item2 {
  padding-top: 50px;
}

.groomsmen-bridesmaid-img {
  position: relative;
  overflow: hidden;
}

.groomsmen-bridesmaid-img img {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  /* width: 260px; */
  /* variant 1 */
  /* width: 306px;  */
  /* variant 2 */
  /* width: 306px;  without width */
  height: 690px;
  /* align-items: center;
  justify-content: center;

  display: flex; */
  /* height: 300px;
   */
  /* height: 375px; */

  object-fit: cover;
}

.groomsmen-bridesmaid-wrap:hover .groomsmen-bridesmaid-img img {
  -webkit-transform: scale(1.2) rotate(5deg);
  -ms-transform: scale(1.2) rotate(5deg);
  transform: scale(1.2) rotate(5deg);
}

.social-list {
  position: absolute;
  left: 0;
  bottom: 10%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1;
  opacity: 0;
  text-align: center;
  transition: all 0.5s;
}

.groomsmen-bridesmaid-wrap {
  overflow: hidden;
  border-top-left-radius: 80px;
  border-bottom-right-radius: 80px;
}
.groomsmen-bridesmaid-wrap-2 {
  border-radius: 0;
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
}

.groomsmen-bridesmaid-wrap:hover .social-list {
  opacity: 1;
}

.social-list ul {
  display: flex;
  justify-content: center;
  z-index: 1;
  list-style: none;
  padding-left: 0;
}

.social-list ul li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 5px;
  z-index: -1;
  background: #b2c9d3;
  transition: all 0.3s;
  border-radius: 50%;
}

.social-list ul li:hover {
  background: #fff;
  color: #333;
}

.social-list ul li a {
  color: #fff;
  transition: all 0.3s;
}

.social-list ul li:hover a {
  color: #b2c9d3;
}

.groomsmen-bridesmaid-content {
  text-align: center;
  background: #fff;
  padding: 15px 0 18px;
}
.groomsmen-bridesmaid-content h4 {
  font-size: 18px;
  color: #151515;
  font-weight: 600;
}

.groomsmen-bridesmaid-content span {
  font-size: 15px;
  font-weight: 400;
}

.groomsmen-bridesmaid-title {
  border-bottom: 2px solid #649e93;
  margin-bottom: 30px;
}

.groomsmen-bridesmaid-title h3 {
  padding: 5px 50px;
  background: #649e93;
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  font-size: 40px;
  font-family: "Great Vibes", cursive;
}

@media (max-width: 992px) {
  .groomsmen-bridesmaid-img img {
    width: 100%;
  }
}

.guest-style .groomsmen-bridesmaid-wrap {
  border-radius: 130px;
}

/* .row {
  justify-content: center;
} */
