.gift-area .slick-slide img{
    width: unset;
}
.gift-area{
    padding: 80px 0;
}
.gift-area .slick-slider{
    padding-left: 100px;
}
@media(max-width:991px){
    .gift-area .slick-slider{
        padding-left: 0px;
    }
}