.mobileMenu {
    position: fixed;
    left: -280px;
    top: 0;
    z-index: 999;
    height: 100vh;
    width: 280px;
    background: #fff;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    overflow-x: auto;
    overflow-y: scroll;
}
.mobileMenu.show{
    left: 0;
}
.showmenu {
    position: fixed;
    right: 15px;
    top: 23px;
    z-index: 999;
    cursor: pointer;
}

.responsivemenu{
    list-style: none;
    padding-left: 0;
}
.header .responsivemenu li a{
    display: block;
    padding: 13px 30px;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #222;
}
.responsivemenu .card{
  border:none
}
.responsivemenu .card-body{
  padding-top: 0;
}
.logo2 {
    padding: 20px;
    border-bottom: 1px solid #d9f3fe;
    padding-bottom: 13px;
}
.logo2 h2 a{
    color:#85aaba ;
}
.responsivemenu .card-body li{
    background: #f5f5f5;
}
.responsivemenu li a{
    position: relative;
}
.responsivemenu li i{
    position: absolute;
    right: 20px;
    top: 17px;
}
.showmenu i{
    font-size: 30px;
    color: #fff;
}
.showmenu{
    display: none;
}
@media (max-width:992px){
    .showmenu{
        display: block;
    }
}

.responsivemenu li p{
    position: relative;
    display: block;
    padding: 13px 30px;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #222;
    cursor: pointer;
}
.responsivemenu li i{
    position: absolute;
    right: 20px;
    top: 17px;
}