.previewHeroArea{
  position: relative;
}

.particaleWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
}

.previewHeroArea:before{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: "";
  opacity: .3;
}

.vedio-area{
  position: relative;
}

