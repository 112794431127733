.page-title {
    background: url("../../images/page-title.jpg");
    height: 550px;
    text-align: center;
    position: relative;
  }
  
  .page-title:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .page-title .container {
    height: 100%;
    display: table;
  }
  
  .page-title .container > .row {
    vertical-align: middle;
    display: table-cell;
  }
  
  .page-title h2,
  .page-title ol {
    color: #fff;
  }
  
  .page-title h2 {
      font-size: 40px;
      font-size: 2.5rem;
      margin-bottom: 0.30em;
      text-transform: capitalize;
      margin-top: 80px;
  }
  
  @media (max-width: 991px) {
    .page-title h2 {
      font-size: 35px;
      font-size: 2.1875rem;
      margin-top: 35px;
    }
  }
  
  @media (max-width: 767px) {
    .page-title h2 {
      font-size: 30px;
      font-size: 1.875rem;
      margin-top: 70px;
    }
  }
  
  .page-title .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
  }
  
  @media (max-width: 767px) {
    .page-title .breadcrumb {
      text-align: center;
    }
  }
  
  .page-title .breadcrumb li {
    font-size: 16px;
    font-size: 1rem;
    color:#b2c9d3;
    margin-right: 5px;
    position: relative;
  }
  
  @media (max-width: 767px) {
    .page-title .breadcrumb li {
      font-size: 14px;
      font-size: 0.875rem;
    }

    .page-title {
      height: 280px;
  }
  }
  
  .page-title .breadcrumb li a {
    color: #fff;
  }
  
  .page-title .breadcrumb li a:hover {
    color:#b2c9d3;
  }
  
  .page-title .breadcrumb > li + li {
    margin-left: 20px;
    padding-left: 15px;
  }
  
  .page-title .breadcrumb > li + li:before {
    content: "/";
    color: #fff;
    padding: 0;
    position: absolute;
    left: -8px;
    top: 2px;
  }
  .breadcrumb {
    justify-content: center;
}