.slick-dots {
  bottom: 30px;
}

.slick-dots li button:before {
  font-size: 14px;
  color: #b2c9d3;
}
.slick-dots li.slick-active button:before {
  color: #b2c9d3;
}

.item2,
.item1 {
  position: relative;
}
.item1:before,
.item2:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: "";
  opacity: 0.3;
}
.items1:before,
.items2:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: "";
  opacity: 0.02;
}

.item1,
.items1 {
  /* background: url(../../images/imagesBubaNIkol/image1.jpeg); */
  background: url(../../images/GeriIvan/predlojenie-2-img.jpg);

  height: 1050px;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.items1 { 
  /* background: url(../../images/imagesBubaNIkol/image-0-slide.jpeg); */
  /* background: url(../../images/GeriIvan/predlojenie-2-img.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .items1 {
    display: flex;
    background-position: center;
    /* background-size: none; */
  }
  .slide-content {
    width: 280px;
    height: 280px;
  }
}

.item2,
.items2 {
  /* background: url(../../images/GeriIvan/predlojenie-2-img.jpg); */
  height: 1050px;
  /* height: 1050px; */
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.items2 {
  /* background: url(../../images//imagesBubaNIkol/image3-slider.jpeg); */
  background: url(../../images/GeriIvan/svatba-landscale-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* left: -10%;
  position: fixed; */
}

/* .img1 {

} */
/* 
.wrap-img {
  position: absolute;
  z-index: 1;
  left: 20;
  width: 100%;

  left: 14%;
} */

/* .wrap-img-2 {
  position: absolute;
} */

/* .img2 { */
/* display: flex; */
/* width: 100%;
   */
/* position: absolute; */
/* top: -182; */
/* max-width: 1850px; */
/* min-width: 100px; */
/* display: flex !important;
  justify-content: center;
  flex-direction: column;
  width: 100%; */
/* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  z-index: 0; */
/* } */

.slide-content {
  text-align: center;
  background: rgba(178, 201, 211, 0.8);
  max-width: 380px;
  height: 380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border-radius: 50%;
  margin-top: 80px;
  position: relative;
  z-index: 1;
}
.animated-circle {
  width: 103%;
  height: 103%;
  background: rgba(178, 201, 211, 0.3);
  border-radius: 50%;
  position: absolute;
  left: -1.5%;
  top: -1.5%;
  -webkit-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
  z-index: -1;
}
.slide-content h2 {
  font-size: 60px;
  color: #fff;
}
.slide-subtitle h4 {
  font-size: 16px;
  color: #fff;
}
.slide-text p {
  color: #fff;
  margin-bottom: 0;
}

@media (max-width: 1599px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .items2 {
    /* background: url(../../images//imagesBubaNIkol/image3-slider.jpeg); */
    left: -9%;
    position: fixed;
  }
  .container-1 {
    position: fixed;
    top: 26%;
    left: 7.5%;
  }
}

@media (max-width: 767px) {
  .slide-content h2 {
    font-size: 35px;
  }
  .slide-content {
    width: 280px;
    height: 280px;
  }
  .item1,
  .item2 {
    /* height: 580px;
     */

    height: 650px;

  }
  .items1,
  .items2 {
    /* height: 580px; */
    height: 650px;

  }
  .item1,
  .items2 {
    position: fixed !important;
    top: 0%;
    left: -43%;
    height: 780px;
  }
  .container-1 {
    position: fixed;
    top: 22%;
    left: 2.5%;
  }
  .item1,
  .items1 {
    /* position: fixed !important; */
    top: -15%;
    left: -23%;
    /* height: 780px; */
  }
  .container-1 {
    position: fixed;
    top: 22%;
    left: 2.5%;
  }
}

.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  border: 1px solid #959595;
  z-index: 10;
  transition: all 0.3s;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  opacity: 1;
  visibility: visible;
}

.slick-prev {
  left: 25px;
}

.slick-prev:before {
  font-family: "FontAwesome";
  content: "\f104";
  opacity: 1;
  color: #fff;
}

.slick-next {
  right: 25px;
}

.slick-next:before {
  font-family: "FontAwesome";
  content: "\f105";
  opacity: 1;
  color: #fff;
}

.slick-prev:hover,
.slick-next:hover {
  background: #85aaba;
  border: none;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #fff;
}

@media (max-width: 767px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  /* .item2,
  .items2 {
    position: fixed;
    top: -18%;
    left: -61%;
    height: 740px;
  } */
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
